import React, {Component} from 'react';
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import {graphql} from "gatsby";
import get from 'lodash/get'
import PageContent from "../components/core/components/PageContent";

class ProvisionTemplate extends Component {
  render() {
    const path = this.props.location.pathname;
    const page = get(this, 'props.data.page');

    return (
      <Layout>
        <SEO title={page.title}
             description={page.metaDescription}
             path={path}/>
       <PageContent page={page} provision={'true'}/>
      </Layout>
    )
  }
}

export default ProvisionTemplate;

export const provisionQuery = graphql`
  query ProvisionQuery($slug: String!) {
    page: contentfulProvision(slug: {eq: $slug}) {
      id
      slug
      title
      metaDescription
      heroImage: heroImage {
        fluid(
          maxWidth: 1920
          quality: 70
        ) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      blocks {
        __typename
        ... on Node {
          ... on ContentfulBlockIntro {
            id
            title
            content {
              json
            }
          }
          ... on ContentfulBlockTextImage {
            id
            imagePosition
            content {
              json
            }
            image: image {
              fluid(
                maxWidth: 1000
                quality: 70
              ) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
